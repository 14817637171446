
export default function Newsletter() {

    return (
        <div className='lets-accelerate-wrapper'>
            <p className='header'>
                Let's accelerate ⏩️
            </p>
            <p className='join-newsletter'>
                <a href="https://acceleration-engineering.us9.list-manage.com/subscribe?u=51743caf14fa2f2c2ab7fcc0c&id=9c7f70569c"
                   target='_blank'
                >
                    Join our newsletter here.
                </a>
            </p>
        </div>
    )
}