
export default function Support() {
    return (
        <>
            <div className='blog-container'>
                <div className='blog-header-wrapper'>
                    <p className='blog-header'>
                        Support
                    </p>
                </div>

                <div className='blog-content-wrapper'>
                    <p className='about-us'>
                        Professional and quick support is critical to our customers' success.
                        We understand it and will respond swiftly to your support requests.
                    </p>

                    <p className='about-us'>
                        We’re available 9am - 6pm Central European Time (CET) on working days and, according to
                        possibility,
                        outside working time. In case of critical issues, we operate in an incident mode, meaning we work
                        as long as required until the issue is resolved.
                    </p>

                    <p className='contact-method'>
                        Live chat on the website
                    </p>
                    <p className='contact-method'>
                        Coming soon.
                    </p>

                    <p className='contact-method margin-top'>
                        E-mail
                    </p>
                    <p className='contact-method'>
                        business@acceleration-engineering.com
                    </p>
                </div>

            </div>
        </>
    )
}