import logo from '../resources/4Acc.png';
import footerLogo from '../resources/5Acc.png';
import linkedInLogo from '../resources/In-White-72.png';
import '../App.css';
import { Outlet, useNavigate } from "react-router-dom";

function Root() {
  const navigate = useNavigate();

  function clickAndCloseMenu(path) {
    switchMobileNav();
    navigate(`/${path}`);
  }

  function handleClick(path) {
    navigate(`/${path}`);
  }

  function switchMobileNav() {
    document.getElementById("mobile-menu").classList.toggle("mobile-menu-active");
  }

  return (
      <>
        <div className="content-wrapper">
          <div className="navbar">
            <div className="navbar-logo">
              <img src={logo} alt="logo" onClick={() => handleClick('')}/>
            </div>
            <div className="navbar-links">
              <div>
                <p className="link" onClick={() => handleClick('philosophy')}>
                  Philosophy
                </p>
              </div>
              <div>
                <p className="link" onClick={() => handleClick('about-us')}>
                  About us
                </p>
              </div>
              <div>
                <p className="link" onClick={() => handleClick('support')}>
                  Support
                </p>
              </div>
              <div>
                <p className="link" onClick={() => handleClick('contact')}>
                  Contact
                </p>
              </div>
            </div>

            <div className='mobile-navbar-wrapper'>
              <i className="fa-regular fa-bars"
                 onClick={() => switchMobileNav()}
              ></i>

              <div className='mobile-menu' id='mobile-menu'>
                <p onClick={() => clickAndCloseMenu('philosophy')}>
                  Philosophy
                </p>
                <p className="link" onClick={() => clickAndCloseMenu('about-us')}>
                  About us
                </p>
                <p className="link" onClick={() => clickAndCloseMenu('support')}>
                  Support
                </p>
                <p className="link" onClick={() => clickAndCloseMenu('contact')}>
                  Contact
                </p>
              </div>
            </div>
          </div>

          <Outlet/>
        </div>

        <div className="footer">
          <div className="footer-content">
            <div className="footer-logo">
              <div className="footer-logo-logo">
                <img src={footerLogo}/>
              </div>

              <div className="footer-logo-address">
                <p className="city">
                  Gdansk, Poland
                </p>
                <p className="street">
                  ul. Leborska 3b
                </p>
                <p className="street">
                  80-386 Gdansk
                </p>
              </div>

              <div className="linkedin-logo">
                <a href="https://www.linkedin.com/company/acceleration-engineering/"
                   target='_blank'
                >
                  <img src={linkedInLogo} alt="linkedin-logo"/>
                </a>
              </div>
            </div>

            <div className='footer-section'>
              <p className='footer-header'>
              Engineering
              </p>
              <p className="link" onClick={() => handleClick('philosophy')}>
                Philosophy
              </p>
              <p className="link" onClick={() => handleClick('support')}>
                Support
              </p>
            </div>

            <div className='footer-section'>
              <p className='footer-header'>
                Company
              </p>
              <p className="link" onClick={() => handleClick('about-us')}>
                About us
              </p>
              <p className="link" onClick={() => handleClick('contact')}>
                Contact
              </p>
            </div>

            <div className='footer-section last-section'>
              <p className='footer-header'>
                Legal
              </p>
              <p className="link" onClick={() => handleClick('privacy')}>
                Privacy Policy
              </p>
            </div>

          </div>
          <div className='copyright'>
            © 2024 Acceleration
          </div>
        </div>
      </>
  );
}

export default Root;
