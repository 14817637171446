import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './routes/root';
import ErrorPage from "./error-page";
import Philosophy from './routes/philosophy';
import Landing from './routes/landing';
import AboutUs from './routes/about-us';
import Contact from './routes/contact';
import Support from './routes/support';
import PrivacyPolicy from './routes/privacy-policy';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <Landing />,
            },
            {
                path: "philosophy",
                element: <Philosophy />,
            },
            {
                path: "about-us",
                element: <AboutUs />,
            },
            {
                path: "support",
                element: <Support />,
            },
            {
                path: "contact",
                element: <Contact />,
            },
            {
                path: "privacy",
                element: <PrivacyPolicy />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);
