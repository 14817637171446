export default function PrivacyPolicy() {
    return (
        <>
            <div className='blog-container'>
                <div className='blog-header-wrapper'>
                    <p className='blog-header'>
                        Privacy Policy
                    </p>
                </div>

                <div className='blog-content-wrapper'>
                    <p className='privacy-paragraph'>
                        We understand that privacy is a highly important aspect for our customers. In this document we
                        describe how we gather and process customer data.
                    </p>

                    <p className='privacy-paragraph'>
                        We gather only the minimum necessary to provide the services and handle it with maximum caution.
                        Keeping our customers' data safe is a major aspect in how we see the professional software
                        business.
                    </p>

                    <p className='privacy-paragraph'>
                        The following privacy policy applies to the website. Privacy policy for the apps
                        will be published with the first app.
                    </p>

                    <p className='privacy-paragraph'>
                        Why do we collect your data
                        <br/>
                        • to inform you about new apps we’ll publish to Atlassian Marketplace and about news regarding
                        our company.
                    </p>

                    <p className='privacy-paragraph'>
                        What data we collect about you
                        <br/>
                        • e-mail for the newsletter.
                    </p>

                    <p className='privacy-paragraph'>
                        How we get the information
                        <br/>
                        • you provide it to us when you click submit in the newsletter signup form.
                    </p>

                    <p className='privacy-paragraph'>
                        How we use the information we collect
                        <br/>
                        • only for the purpose of sending you an email regarding new apps and company news.
                    </p>

                    <p className='privacy-paragraph'>
                        How we share the information we collect
                        <br/>
                        • the e-mail address is automatically stored in MailChimp service. Besides that, we don’t share
                        it with anyone and anywhere.
                    </p>

                    <p className='privacy-paragraph'>
                        How we store the information we collect
                        <br/>
                        • the e-mail address is stored in MailChimp service. We don’t have our own database for keeping
                        the data.
                    </p>

                    <p className='privacy-paragraph'>
                        In what countries the data is stored
                        <br/>
                        • the e-mail address is stored in United States.
                    </p>

                    <p className='privacy-paragraph'>
                        How we transfer the information we collect internationally
                        <br/>
                        • we do not transfer the data we collect internationally.
                    </p>

                    <p className='privacy-paragraph'>
                        How long we keep the information
                        <br/>
                        • until you click “Unsubscribe” in the e-mail footer or until you ask us to remove your e-mail
                        by contacting us on business@acceleration-engineering.com
                    </p>

                    <p className='privacy-paragraph'>
                        How to control your information
                        <br/>
                        • you can remove your e-mail address from our newsletter by clicking “Unsubscribe” in the e-mail
                        footer or by sending us an e-mail with a request on business@acceleration-engineering.com.
                    </p>

                    <p className='privacy-paragraph'>
                        What third-party tools we use
                        <br/>
                        • We use&nbsp;
                        <a href="https://plausible.io/"
                           target='_blank'
                        >Plausible.io
                        </a>
                        , an open-source and privacy-friendly analytics tool to see the
                        trends in page traffic. See&nbsp;
                        <a href="https://plausible.io/about"
                           target='_blank'
                        >About Plausible
                        </a>
                        &nbsp;page to understand the philosophy behind it. Here’s Plausible’s&nbsp;
                        <a href="https://plausible.io/data-policy"
                           target='_blank'
                        >data policy
                        </a>
                        . Plausible does not require cookies.
                        <br/>
                        • We use&nbsp;
                        <a href="https://mailchimp.com/"
                           target='_blank'
                        >
                            MailChimp
                        </a>
                        &nbsp;for the e-mail newsletter service.&nbsp;
                        <a href="https://mailchimp.com/legal/terms/"
                           target='_blank'
                        >Here
                        </a>
                        &nbsp;you can find MailChimp’s Terms of Use.
                    </p>
                </div>
            </div>
        </>
    )
}