import Newsletter from '../newsletter';

export default function AboutUs() {
    return (
        <>
            <div className='blog-container'>
                <div className='blog-header-wrapper'>
                    <p className='blog-header'>
                        About us
                    </p>
                </div>

                <div className='blog-content-wrapper'>
                    <p className='about-us'>
                        Product • Discovery • Engineering • Opportunity • Value • Acceleration • Success
                    </p>

                    <p className='about-us'>
                        Five years of employment for Atlassian enriched us with the solid knowledge and experience
                        required to build high-quality, secure and reliable cloud solutions for our customers.
                    </p>

                    <p className='about-us'>
                        A vast knowledge of the Atlassian Ecosystem helps us to see the problem comprehensively,
                        and navigate through hundreds of specialized resources to solve the issue quickly.
                        We see the big picture and connect the dots to provide the best solutions for our customers.
                    </p>

                    <p className='about-us'>
                        We understand the sophisticated Atlassian security requirements,
                        as we had to comply with all of these while contributing to Jira and Forge extension points.
                    </p>

                    <p className='about-us'>
                        Prior to founding Acceleration, our engineers played a crucial role in developing&nbsp;
                        <a href="https://developer.atlassian.com/platform/forge/manifest-reference/modules/jira-ui-modifications/"
                           target='_blank'
                        >
                            Jira UI modifications
                        </a>,
                        one of the most complex and powerful Jira extension points.
                        This API is backing e.g.&nbsp;
                        <a href="https://marketplace.atlassian.com/apps/1228522/behaviours-scriptrunner-for-jira-cloud"
                           target='_blank'
                        >
                            ScriptRunner's Behaviours for Jira Cloud
                        </a>.
                    </p>

                    <p className='about-us'>
                        We understand Atlassian's recommendations regarding apps, and the value behind them.
                        We support the vision of Forge, a hosted-by-Atlassian and secure-by-design platform,
                        and we aim to contribute to and grow this ecosystem.
                    </p>

                    <p className='about-us'>
                        Stay in touch, and let's ignite a spark of new energy and new quality in the Atlassian Ecosystem together.
                    </p>
                </div>

                <Newsletter />
            </div>
        </>
    )
}